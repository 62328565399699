@import "../../vars.css";

.container {
  position: relative;
  background: rgba(255, 255, 255, 0.26);
  border-radius: 15px;
  padding: 73px;
  margin-top: 180px;
  z-index: 1;
  margin-left: 10%;
  margin-right: 10%;
 border: 5px solid var(--orangeCard);
}

.title {
  color: #242D49;
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  text-align: center;
}
.title>span{
  color:  #FCA61F;
  font-size: 35px;
  letter-spacing: 1.75px;
  text-transform: uppercase;
    
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aboutImage {
  width: 35%;
}

.aboutItems {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.aboutItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  list-style: none;
  padding: 15px;
  background: #fbf8f8;
  background-image: linear-gradient(
    90deg,
    rgba(150, 214, 236, 0.42) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}

.aboutItem:hover {
  background-size: 100% 100%;
}

.aboutItem h3 {
  font-size: 22px;
  font-weight: 600;
}

.aboutItem p {
  font-size: 20px;
}

@media (max-width: 830px) {
  .container {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .content {
    flex-direction: column;
  }

  .aboutImage {
    display: none;
  }

  .aboutItems {
    margin-top: 29px;
  }

  .aboutItem {
 padding: 0;
 margin: 0;
  }
  .aboutItems{
    margin: 5px;
    padding: 0;
  }
  .aboutItem h3{
    font-size: 18px;
  }
  .aboutItem p{
    font-size: 16px;
  }
}
