@import "../../vars.css";

.container {
  color: var(--color-text);
  margin-top: 79px;
  margin-left: 7%;
  margin-right: 7%;
 border: 5px solid var(--orangeCard);
}

.title {
  color:  #FCA61F;
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 14px;
  margin-bottom: 5px;
}

.skills {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
}

.skill {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 55px;
}

.skillImageContainer {
  background-color: var(--color-secondary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin: 10px;
  flex-direction: column;
  gap: 0;
}

.skillImageContainer img {
  width: 60px;
}

.skill p {
  font-size: 25px;
  font-family: var(--font-roboto);
  font-weight: 500;
}
.skillImageContainer>h4{
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}



@media screen and (max-width: 830px) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .skills {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .skill{
    gap:5px
  }
  .container{
    margin-left: 0;
    margin-right: 0;
  }
  .skillImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width:120px;
    height: 140px;
    margin: 6px;
  }
  .skillImageContainer img {
    width: 50px;
  }
  .skillImageContainer>h4{
    font-size: 16px;
  }
}