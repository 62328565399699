:root {
    /* Colours */
    --color-text: #fff;
    --color-primary: #576cbc;
    --color-secondary: #19376d;
    --color-dark: #0b2447;
    --color-bg: #04152d;
  
    /* Fonts */
    --font-roboto: "Roboto";
  }